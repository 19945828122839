import React, { Component, useState, useEffect, useMemo } from 'react';
import {
  RecoilRoot,
  atom,
  selector,
  useRecoilState,
  useRecoilValue,
} from 'recoil';

import loadable from "@loadable/component";

import {
  BrowserRouter,
  Routes,
  createBrowserRouter,
  RouterProvider,
  Route,
  Navigate,
  useNavigate,
  useLocation,
  Link
} from 'react-router-dom';
import { withTranslation, Translation, } from 'react-i18next';

import { isLogin, profileExists, isReservedPath } from './utils';
import i18n from "./i18n";

import LoadingPage from "./components/pages/home/LoadingPage";


const viewportWidth = window.innerWidth;
const viewportHeight = window.innerHeight;




const HomePage = loadable(() => import("./components/pages/home/HomePage"), {
   fallback: <LoadingPage />
});
const AppelProjetsPage = loadable(() => import("./components/pages/home/AppelProjetsPage"), {
   fallback: <LoadingPage />
});
const WhoswhoPage = loadable(() => import("./components/pages/home/WhoswhoPage"), {
   fallback: <LoadingPage />
});
const PartenairesPage = loadable(() => import("./components/pages/home/PartenairesPage"), {
   fallback: <LoadingPage />
});
const TalkDesignPage = loadable(() => import("./components/pages/home/TalkDesignPage"), {
   fallback: <LoadingPage />
});
const JoinTalkDesignPage = loadable(() => import("./components/pages/home/JoinTalkDesignPage"), {
   fallback: <LoadingPage />
});
const NewsroomPage = loadable(() => import("./components/pages/home/NewsroomPage"), {
   fallback: <LoadingPage />
});
const AccPressePage = loadable(() => import("./components/pages/home/AccPressePage"), {
   fallback: <LoadingPage />
});





/*
const ChartPage = loadable(() => import("./components/pages/home/ChartPage"), {
   fallback: <LoadingPage />
});

const NewsPage = loadable(() => import("./components/pages/home/NewsPage"), {
   fallback: <LoadingPage />
});

const StoriesPage = loadable(() => import("./components/pages/home/StoriesPage"), {
   fallback: <LoadingPage />
});



const ContactPage = loadable(() => import("./components/pages/home/ContactPage"), {
   fallback: <LoadingPage />
});

const PrivacyPage = loadable(() => import("./components/pages/general/PrivacyPage"), {
   fallback: <LoadingPage />
});

const TermsPage = loadable(() => import("./components/pages/general/TermsPage"), {
   fallback: <LoadingPage />
});
*/

const NoMatch = loadable(() => import("./components/pages/home/NoMatch"), {
   fallback: <LoadingPage />
});



const runMemorizeCards = cards => {
  console.log('>>> run runMemorizeCards :: ' + cards.length);
  return cards
}




var ws;

const App = () => {

      const currentYear = new Date().getFullYear();

      window.onorientationchange = (event) => {
         window.location.reload();
      };



    //  const [cards, setCards] = useState([]);
    //  const cardsResult = useMemo(() => runMemorizeCards(cards), [cards]);





      useEffect(() => {





          fetch('https://api.ipstack.com/check?access_key=9cd4ffbf45a257de9e644d4d875a9465')
           		  	.then((response) => response.json())
           		  	.then((responseData) => {
           			  	   //alert("App. Store: " + JSON.stringify(responseData));

                       global.country = responseData.country_code;
                       global.continent = responseData.continent_code;
                       global.ip = responseData.ip;

                       global.myContinentCode = responseData.continent_code;
                       global.myCountryCode = responseData.country_code;
                       global.myRegion = responseData.region_name;
                       global.myCity = responseData.city;
                       global.myIP = responseData.ip;


           		      })
           		     .catch(function(error) {

           		     });



      }, []);





       return (
         <Translation>
           {
               t =>
              <RecoilRoot>
               <BrowserRouter>
                 <Routes>
                   <Route path="/" element={<HomePage />} />
                   <Route path="/appel_a_projets" element={<AppelProjetsPage />} />
                   <Route path="/partenaires" element={<PartenairesPage />} />
                   <Route path="/talk_design" element={<TalkDesignPage />} />
                   <Route path="/rejoindre_talkdesign" element={<JoinTalkDesignPage />} />
                   <Route path="/newsroom" element={<NewsroomPage />} />
                   <Route path="/tekdesign" element={<WhoswhoPage />} />
                   <Route path="/accreditation_presse" element={<AccPressePage />} />
                   <Route path="*" element={<NoMatch />} />
                 </Routes>
               </BrowserRouter>



             </RecoilRoot>
            }
        </Translation>
      );

}

export default App;
