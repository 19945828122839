import React, { Component } from 'react';
import { withTranslation, Translation, } from 'react-i18next';

import {BrowserView,MobileView,TabletView, isMobileOnly, isTablet} from 'react-device-detect';

import { View, Image, ActivityIndicator} from "react-native-web";



const viewportWidth = window.innerWidth;
const viewportHeight = window.innerHeight;

export default function LoadingPage() {




    return (

	         <div>


                  {!isMobileOnly && !isTablet &&
                    <BrowserView>
                      <View style={{width:viewportWidth, height: viewportHeight, justifyContent: 'center', alignSelf: 'center', alignItems: 'center', backgroundColor: '#FF5C02'}}>



                         <Image source={{uri: 'https://s3.amazonaws.com/kids.omoy/logo_kids_fond_orange_transp.png'}} style={{width: 260*1.4,height: 80*1.4, marginBottom: 60, alignSelf: 'center'}}/>


                          <ActivityIndicator  animating={true} color="#fff" />
                      </View>
                   </BrowserView>
                 }



                 {isMobileOnly &&
           			  <MobileView>
                    <View style={{width:viewportWidth, height: viewportHeight, justifyContent: 'center', alignSelf: 'center', backgroundColor: '#FF5C02'}}>



                      <Image source={{uri: 'https://s3.amazonaws.com/kids.omoy/logo_kids_fond_orange_transp.png'}} style={{width: 200*1.3,height: 61*1.3, marginBottom: 60, alignSelf: 'center'}}/>



                      <ActivityIndicator  animating={true} color="#fff" />
                    </View>
           			 </MobileView>
           			}

           			{isTablet &&
           			  <TabletView>
                      <View style={{width:viewportWidth, height: viewportHeight, justifyContent: 'center', alignSelf: 'center', backgroundColor: '#FF5C02'}}>

                  

                        <Image source={{uri: 'https://s3.amazonaws.com/kids.omoy/logo_kids_fond_orange_transp.png'}} style={{width: 260*1.4,height: 80*1.4, marginBottom: 60, alignSelf: 'center'}}/>



                        <ActivityIndicator  animating={true} color="#fff" />
                      </View>
           			  </TabletView>
           			}








	        </div>

    )

}
