import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { RecoilRoot } from "recoil";



global.country = 'CD';
global.continent = 'AF';
global.ip = '';
global.reloadobj = null;
global.rate = 2060;

global.displayModal = false;
global.selectedFilter = '';
global.menuFilterClicked = false;

global.myContinentCode = '';
global.myCountryCode = '';
global.myRegion = '';
global.myCity = '';
global.myIP = '';

//const stripePromise = loadStripe('pk_live_1NtV1g1sGnZEVL2BfkAxsSiu');
//const stripePromise = loadStripe('pk_test_6qz6Ir009Ktjzv1MIRZBATUV');


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
      <RecoilRoot>
        <App />
      </RecoilRoot>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
